import * as React from "react"

export const NavigationContext = React.createContext({
	isNavVisible: false,
	toggleNavigation: () => {},
})

export const NavigationProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const [isNavVisible, setIsNavVisible] = React.useState(false)

	const toggleNavigation = () => {
		setIsNavVisible(!isNavVisible)
	}

	return (
		<NavigationContext.Provider value={{ isNavVisible, toggleNavigation }}>
			{children}
		</NavigationContext.Provider>
	)
}
