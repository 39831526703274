/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.scss"
import * as React from "react"
import { NavigationProvider } from "./src/context/navigation"

export const wrapRootElement = ({ element }) => {
	return <NavigationProvider>{element}</NavigationProvider>
}
